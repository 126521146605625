import React, { useState, useContext, useEffect } from 'react';
import MapContext from "./Map/MapContext";
import OLImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import RasterSource from 'ol/source/Raster';
import XYZ from 'ol/source/XYZ';

const DynamicTileLayer = (props) => {
    const {
        costFunction='0',
        projection='EPSG:3857',
        minZoom=5,
        maxZoom=10,
        operation,
        ...other
    } = props;

    const [layer, setLayer] = useState(null);

    other.source = new RasterSource({
        sources: [
            new TileLayer({
                type: 'base',
                source: new XYZ({
                    url: "https://tiled-{a-c}.places.jackohare.com/tile/{z}/{x}/{y}/" + encodeURIComponent(costFunction),
                    projection: projection,
                    minZoom: minZoom,
                    maxZoom: maxZoom,
                    crossOrigin: 'anonymous'
                })
            }),
        ],
        operation: operation
    });

    const { map } = useContext(MapContext); 
    useEffect(() => {
        if (!map) return;
        if (layer != null) {
            map.removeLayer(layer);
        }
        let tileLayer = new OLImageLayer(other);
        map.addLayer(tileLayer);
        setLayer(tileLayer);
        return () => {
            if (map) {
                map.removeLayer(tileLayer);
            }
        };
    }, [map, costFunction]);
    
    return null; //(<p>{costFunction}</p>);
};
export default DynamicTileLayer;
