import React, { useContext, useEffect, useState } from "react";
import MapContext from "../MapContext";
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import LayerSwitcher from 'ol-layerswitcher';

const LayerSwitcherControl = () => {
    const { map } = useContext(MapContext);
    useEffect(() => {
        if (!map) return;
        let control = new LayerSwitcher();
        map.controls.push(control);
        return () => map.controls.remove(control);
    }, [map]);

    return null;
};

export default LayerSwitcherControl;
