import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import CostFunctionBuilder from './CostFunctionBuilder.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import { datasets, dataset_categories } from './datasets.js';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { SvgIconProps } from '@mui/material/SvgIcon';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  const result = (
    <StyledTreeItemRoot 
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
    return result;
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};


export default class LayerChooser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            editingLayerId: "",
            costFunctionParts: [],
            modalEquation: '0',
        };
    }

    openModal = (id) => {
        this.setState({modalIsOpen: true, editingLayerId: id});
    };

    cancelModal = () => {
        this.setState({modalIsOpen: false});
    };
    
    acceptModal = () => {
        const newCostFunctionParts = [];
        for (let i=0; i<this.state.costFunctionParts.length; i++) {
            if (this.state.costFunctionParts[i].layerId != this.state.editingLayerId) {
                newCostFunctionParts.push(this.state.costFunctionParts[i]);
            }
        }
        newCostFunctionParts.push({layerId: this.state.editingLayerId, equation: this.state.modalEquation});
        this.setState({modalIsOpen: false, costFunctionParts: newCostFunctionParts});

        var newCostFunction = '';
        for (let newCostFunctionPart of newCostFunctionParts) {
            if (newCostFunction != '') {
                newCostFunction = newCostFunction + ' + ';
            }
            newCostFunction = newCostFunction + newCostFunctionPart.equation;
        }
        if (this.props.onCostFunctionChange) {
            this.props.onCostFunctionChange(newCostFunction);
        } 
    };

    makeTreeItemsLeaf(items) {
        if (!items) {
            return null;
        }
        const result = [];
        for (let item of items) {
            result.push(<StyledTreeItem nodeId={item.id} key={item.id} labelText={item.title} labelIcon={item.icon ? item.icon : null} data-layerid={item.id} onClick={event => { this.openModal(item.id); }} />);
        }
        return result;
    }

    makeTreeItems() {
        var dict = {};
        for (let dataset of datasets) {
            var category = dataset.categories[0];
            var key;
            if (dataset.categories.length > 1) {
                var subcategory = dataset.categories[1];
                key = category + '.' + subcategory;
            } else {
                key = category;
            }
            if (!dict[key]) {
                dict[key] = [];
            }
            dict[key].push(dataset);
        }
        
        const result = [];
        for (let dataset_category of dataset_categories) {
            var cat = [];
            if (dataset_category.subcategories) {
                for (let subcategory of dataset_category.subcategories) {
                    var key = dataset_category.id + '.' + subcategory.id;
                    var itemResults = this.makeTreeItemsLeaf(dict[key]);
                    cat.push(
                        <StyledTreeItem nodeId={key} key={key} labelText={subcategory.title} labelIcon={subcategory.icon ? subcategory.icon : null}>
                            {itemResults}
                        </StyledTreeItem>
                    );
                }
            }
            cat = cat.concat(this.makeTreeItemsLeaf(dict[dataset_category.id]));
            result.push(
                <StyledTreeItem nodeId={dataset_category.id} key={dataset_category.id} labelText={dataset_category.title} labelIcon={dataset_category.icon ? dataset_category.icon : null}>
                    {cat}
                </StyledTreeItem>
            );
        }
        return result;
    }

    render() {
        const treeItems = this.makeTreeItems();
        const debugCostFunctionComponents = [];
        var i=0;
        for (let costFunctionPart of this.state.costFunctionParts) {
            i++;
            debugCostFunctionComponents.push(<p key={'debug_fn_'+i}>{costFunctionPart.equation}</p>);
        }
        return (
            <div>
              <TreeView
                  defaultCollapseIcon={<ArrowDropDownIcon />}
                  defaultExpandIcon={<ArrowRightIcon />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                  sx={{ /*flexGrow: 1,*/ overflowY: 'auto' }} >
                  {treeItems}
              </TreeView>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    //onAfterOpen={afterOpenModal}
                    //onRequestClose={closeModal}
                    //style={customStyles}
                    contentLabel="Example Modal"
                    layerChooser={this}
                    appElement={document.getElementById('root') || undefined}
                >
                  <div class="d-flex flex-column h-100">
                    <div class="flex-grow-1 flex-shrink-1">
                      <CostFunctionBuilder layerId={this.state.editingLayerId}
                                           onEquationChange={equation => { this.setState({modalEquation: equation}); }} />
                    </div>
                    <div class="flex-grow-0 flex-shrink-0">
                      <button onClick={this.acceptModal}>Update Map</button>
                      <button onClick={this.cancelModal}>Cancel</button>
                    </div>
                  </div>
                </Modal>

                {/*<div>
                    {debugCostFunctionComponents}
                   </div>*/}
            </div>
        );
    }
    
}
