import React from 'react';
import './ManualCostFunctionEntry.css';

export default class ManualCostFunctionEntry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            questions: props.questions,
        }
    }

    handleChange(i, e) {
        const copy = this.state.questions;
        copy[i].y = e.target.value;
        this.setState({questions: copy});
        if (this.props.onChange != null) {
            this.props.onChange();
        }
    }
    
    render() {
        const rows = [];
        for (let i=0; i<this.state.questions.length; i++) {
            const question = this.state.questions[i];
            rows.push(
                <tr key={'ManualCostFunctionEntry_' + question.x}>
                    <td>{question.template1}</td>
                    <td>{question.x}</td>
                    <td>{question.template2}</td>
                    <td class="cfb-manual-entry-entry">
                        <input type="text" tabIndex={0} value={question.y} onChange={ (e) => { this.handleChange(i, e) } } />
                    </td>
                    <td>{question.template3}</td>
                </tr>
            );
        }
        return (
            <div class="cfb-manual-entry">
                <table>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}
