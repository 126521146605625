import { useContext, useEffect } from "react";
import MapContext from "../MapContext";
import OLVectorTileLayer from 'ol/layer/VectorTile';

const VectorTileLayer = (inProps) => {
    const {style, ...props} = inProps;
    const { map } = useContext(MapContext);
    if (style instanceof Function) {
        props.style = style(map);
    } else if (style) {
        props.style = style;
    }
    useEffect(() => {
        if (!map) return;
        let tileLayer = new OLVectorTileLayer(props);
        map.addLayer(tileLayer);
        //tileLayer.setZIndex(zIndex);
        return () => {
      if (map) {
          map.removeLayer(tileLayer);
      }
        };
    }, [map]);
    return null;
};
export default VectorTileLayer;
