import { Thermostat, Groups, HowToVote, Savings, BroadbandOnPersonal, PriceChange, School, Forest, BeachAccess, LocalHospital, WbSunny, Home, Apartment, Diversity3, Man, Woman, Star, NaturePeople, HealthAndSafety, Interests, Work, Percent  } from '@mui/icons-material';

export const dataset_categories = [
    {
        id:'demographics',
        title: "Demographics",
        icon: Groups,
        subcategories: [{
            id: 'politics',
            title: "Politics",
            icon: HowToVote,
        }, {
            id: 'income',
            title: "Income",
            icon: Work,
        }, {
            id: 'education',
            title: "Education",
            icon: School,
        }, {
            id: 'diversity',
            title: "Diversity",
            icon: Diversity3,
        }]
    }, {
        id: 'finance',
        title: "Finance",
        icon: Savings,
        subcategories: [{
            id: 'rent',
            title: "Rent Prices",
            icon: Apartment,
        }, {
            id: 'buy',
            title: "Home Prices",
            icon: Home,
        }, {
            id: 'tax',
            title: "Income Tax",
            icon: Percent,
        }]
    }, {
        id: 'amenities',
        title: "Amenities",
        icon: Star
    }, {
        id: 'weather',
        title: "Weather",
        icon: WbSunny,
    }, {
        id: 'environment',
        title: "Environment",
        icon: NaturePeople,
    }, {
        id: 'safety',
        title: "Safety",
        icon: HealthAndSafety,
    }, {
        id: 'other',
        title: "Other",
        icon: Interests
    }
];

export const datasets = [
    {
        id: 'trump_pct',
        title: 'Trump Voters',
        formatter: 'percent',
        categories: ['demographics', 'politics'],
        questions: [
            {template1: 'I\'d live somewhere with ', x: 10, template2: 'Trump voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 20, template2: 'Trump voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 30, template2: 'Trump voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 40, template2: 'Trump voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 50, template2: 'Trump voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 60, template2: 'Trump voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 70, template2: 'Trump voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 80, template2: 'Trump voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 90, template2: 'Trump voters if you paid me', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'clinton_pct',
        title: 'Clinton Voters',
        categories: ['demographics', 'politics'],
        formatter: 'percent',
        questions: [
            {template1: 'I\'d live somewhere with ', x: 10, template2: 'Clinton voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 20, template2: 'Clinton voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 30, template2: 'Clinton voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 40, template2: 'Clinton voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 50, template2: 'Clinton voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 60, template2: 'Clinton voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 70, template2: 'Clinton voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 80, template2: 'Clinton voters if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 90, template2: 'Clinton voters if you paid me', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_acres_per_person',
        title: 'Population Density',
        categories: ['other'],
        formatter: 'number',
        questions: [
            {template1: 'I\'d live somewhere with ', x: 0.005739205014504, template2: ' acres land per person (250sqft) if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 0.011478410029008, template2: ' acres land per person (500sqft) if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 0.022956820058017, template2: ' acres land per person (1000sqft) if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 0.045913640116033, template2: ' acres land per person (2000sqft) if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 0.114784100290082, template2: ' acres land per person (1000sqft) if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 0.25, template2: ' acres per person if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 0.5, template2: ' acres per person if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 1, template2: ' acres per person if you paid me', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 10, template2: ' acres per person if you paid me', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_p75_rent',
        title: 'Monthly Rent: 75th Percentile',
        categories: ['finance', 'rent'],
        formatter: 'dollars',
        questions: [
            {template1: 'I\'d live somewhere where the 75th percentile rent is ', x: 500, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile rent is ', x: 750, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile rent is ', x: 1000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile rent is ', x: 1500, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile rent is ', x: 2000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile rent is ', x: 3000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile rent is ', x: 4000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile rent is ', x: 5000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile rent is ', x: 10000, template2: ' if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_p75_housing_unit_value',
        title: '75th Percentile House Price',
        categories: ['finance', 'buy'],
        formatter: 'dollars',
        questions: [
            {template1: 'I\'d live somewhere where the 75th percentile house costs ', x: 20000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile house costs ', x: 50000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile house costs ', x: 100000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile house costs ', x: 200000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile house costs ', x: 400000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile house costs ', x: 600000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile house costs ', x: 800000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile house costs ', x: 1000000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile house costs ', x: 2000000, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the 75th percentile house costs ', x: 5000000, template2: ' if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_pct_households_with_computer_with_broadband',
        title: 'Broadband Access',
        categories: ['amenities'],
        formatter: 'percent',
        questions: [
            {template1: 'I\'d live somewhere where ', x: 10, template2: ' % of households have broadband if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 20, template2: ' % of households have broadband if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 30, template2: ' % of households have broadband if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 40, template2: ' % of households have broadband if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 50, template2: ' % of households have broadband if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 60, template2: ' % of households have broadband if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 70, template2: ' % of households have broadband if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 80, template2: ' % of households have broadband if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 90, template2: ' % of households have broadband if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_pct_households_income_over_150000',
        title: 'Household Income > $150,000',
        categories: ['demographics', 'income'],
        questions: [
            {template1: 'I\'d live somewhere where ', x: 10, template2: ' % of households make > $150,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 20, template2: ' % of households make > $150,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 30, template2: ' % of households make > $150,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 40, template2: ' % of households make > $150,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 50, template2: ' % of households make > $150,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 60, template2: ' % of households make > $150,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 70, template2: ' % of households make > $150,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 80, template2: ' % of households make > $150,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 90, template2: ' % of households make > $150,000 per year if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_pct_households_income_over_75000',
        title: 'Household Income > $75,000',
        categories: ['demographics', 'income'],
        questions: [
            {template1: 'I\'d live somewhere where ', x: 10, template2: ' % of households make > $75,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 20, template2: ' % of households make > $75,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 30, template2: ' % of households make > $75,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 40, template2: ' % of households make > $75,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 50, template2: ' % of households make > $75,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 60, template2: ' % of households make > $75,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 70, template2: ' % of households make > $75,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 80, template2: ' % of households make > $75,000 per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 90, template2: ' % of households make > $75,000 per year if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_pct_occupied_housing_units_occupied_by_owner',
        title: 'Home Ownership',
        categories: ['demographics'],
        questions: [
            {template1: 'I\'d live somewhere where ', x: 10, template2: ' % of households own if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 20, template2: ' % of households own if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 30, template2: ' % of households own if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 40, template2: ' % of households own if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 50, template2: ' % of households own if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 60, template2: ' % of households own if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 70, template2: ' % of households own if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 80, template2: ' % of households own if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 90, template2: ' % of households own if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_pct_population_female',
        title: 'Female Population',
        categories: ['demographics'],
        questions: [
            {template1: 'I\'d live somewhere where ', x: 40, template2: ' % of the population is female if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 45, template2: ' % of the population is female if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 50, template2: ' % of the population is female if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 55, template2: ' % of the population is female if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 60, template2: ' % of the population is female if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_pct_population_male',
        title: 'Male Population',
        categories: ['demographics'],
        questions: [
            {template1: 'I\'d live somewhere where ', x: 40, template2: ' % of the population is male if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 45, template2: ' % of the population is male if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 50, template2: ' % of the population is male if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 55, template2: ' % of the population is male if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 60, template2: ' % of the population is male if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_pct_population_over_25_education_masters_plus',
        title: 'Education: Masters',
        categories: ['demographics', 'education'],
        questions: [
            {template1: 'I\'d live somewhere where ', x: 0, template2: ' % of the adult population has a master\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 1, template2: ' % of the adult population has a master\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 2, template2: ' % of the adult population has a master\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 5, template2: ' % of the adult population has a master\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 10, template2: ' % of the adult population has a master\'s degree if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_pct_population_over_25_education_bachelors_plus',
        title: 'Education: College',
        categories: ['demographics', 'education'],
        questions: [
            {template1: 'I\'d live somewhere where ', x: 0, template2: ' % of the adult population has a bachelor\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 1, template2: ' % of the adult population has a bachelor\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 2, template2: ' % of the adult population has a bachelor\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 5, template2: ' % of the adult population has a bachelor\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 10, template2: ' % of the adult population has a bachelor\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 20, template2: ' % of the adult population has a bachelor\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 30, template2: ' % of the adult population has a bachelor\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 40, template2: ' % of the adult population has a bachelor\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 50, template2: ' % of the adult population has a bachelor\'s degree if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 75, template2: ' % of the adult population has a bachelor\'s degree if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_pct_population_over_25_education_high_school_plus',
        title: 'Education: High School',
        categories: ['demographics', 'education'],
        questions: [
            {template1: 'I\'d live somewhere where ', x: 0, template2: ' % of the adult population has a high school diploma if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 10, template2: ' % of the adult population has a high school diploma if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 20, template2: ' % of the adult population has a high school diploma if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 30, template2: ' % of the adult population has a high school diploma if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 40, template2: ' % of the adult population has a high school diploma if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 50, template2: ' % of the adult population has a high school diploma if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 60, template2: ' % of the adult population has a high school diploma if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 70, template2: ' % of the adult population has a high school diploma if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 80, template2: ' % of the adult population has a high school diploma if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'acs_2018_5y_pct_population_white',
        title: 'White Population',
        categories: ['demographics', 'diversity'],
        questions: [
            {template1: 'I\'d live somewhere where ', x:   0, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x:  10, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x:  20, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x:  30, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x:  40, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x:  50, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x:  60, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x:  70, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x:  80, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x:  90, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where ', x: 100, template2: ' % of the population is white if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'feels_like_daily_max_p05',
        title: 'Cold Weather',
        categories: ['weather'],
        questions: [
            {template1: 'I\'d live somewhere where the daily high temperature is below ', x:  0, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is below ', x: 10, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is below ', x: 20, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is below ', x: 30, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is below ', x: 40, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is below ', x: 50, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is below ', x: 60, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is below ', x: 70, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'feels_like_daily_max_p95',
        title: 'Hot Weather',
        categories: ['weather'],
        questions: [
            {template1: 'I\'d live somewhere where the daily high temperature is above ', x:  60, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is above ', x:  70, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is above ', x:  80, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is above ', x:  90, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is above ', x: 100, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is above ', x: 110, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the daily high temperature is above ', x: 120, template2: 'F for more than a month per year if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'forest_cover',
        title: 'Forest',
        categories: ['environment'],
        questions: [
            {template1: 'I\'d live somewhere with ', x: 0, template2: ' (without) forest cover if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 1, template2: ' (with) forest cover if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'hospital_travel_seconds',
        title: 'Hospital Availability',
        categories: ['amenities'],
        questions: [
            {template1: 'I\'d live somewhere where the nearest hospital is ', x:  0, template2: ' minutes away if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the nearest hospital is ', x: 10, template2: ' minutes away if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the nearest hospital is ', x: 20, template2: ' minutes away if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the nearest hospital is ', x: 30, template2: ' minutes away if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the nearest hospital is ', x: 40, template2: ' minutes away if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the nearest hospital is ', x: 50, template2: ' minutes away if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere where the nearest hospital is ', x: 60, template2: ' minutes away if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'is_indian_reservation',
        title: 'Indian Reservations',
        categories: ['other'],
        questions: [
            {template1: 'I\'d live ', x: 0, template2: ' (off) an indian reservation if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live ', x: 1, template2: ' (on) an indian reservation if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'is_military_base',
        title: 'Military Bases',
        categories: ['other'],
        questions: [
            {template1: 'I\'d live ', x: 0, template2: ' (off) a military base if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live ', x: 1, template2: ' (on) a military base if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'lyme_cases_per_million_10year_2018',
        title: 'Lyme Disease',
        categories: ['safety'],
        questions: [
            {template1: 'I\'d live somewhere with ', x:     0, template2: ' Lyme disease cases per million people per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 10000, template2: ' Lyme disease cases per million people per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 20000, template2: ' Lyme disease cases per million people per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 30000, template2: ' Lyme disease cases per million people per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 40000, template2: ' Lyme disease cases per million people per year if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 50000, template2: ' Lyme disease cases per million people per year if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'mean_uvb',
        title: 'UV-B Radiation (Sunshine)',
        categories: ['weather'],
        questions: [
            {template1: 'I\'d live somewhere with ', x: 58143, template2: ' J/m² UV-B radiation (like Seattle) if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 73309, template2: ' J/m² UV-B radiation (like Research Triangle) if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 81816, template2: ' J/m² UV-B radiation (like OKC or Orlando) if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live somewhere with ', x: 99464, template2: ' J/m² UV-B radiation (like Tuscon) if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'volcano_hazard_zone',
        title: 'Volcano Hazard Zones',
        categories: ['safety'],
        questions: [
            {template1: 'I\'d live ', x: 0, template2: ' (outside of) a volcano hazard zone if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live ', x: 1, template2: ' (in) a volcano hazard zone if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'west_coast_elevation_m',
        title: 'Elevation',
        categories: ['environment'],
        questions: [
            {template1: 'I\'d live at ', x: 0, template2: ' meters altitude if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live at ', x: 10, template2: ' meters altitude if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live at ', x: 25, template2: ' meters altitude if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live at ', x: 50, template2: ' meters altitude if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live at ', x: 100, template2: ' meters altitude if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live at ', x: 200, template2: ' meters altitude if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live at ', x: 500, template2: ' meters altitude if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live at ', x: 1000, template2: ' meters altitude if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live at ', x: 2000, template2: ' meters altitude if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d live at ', x: 3000, template2: ' meters altitude if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_10000',
        title: 'Income Tax on $10K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_25000',
        title: 'Income Tax on $25K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_30000',
        title: 'Income Tax on $30K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_40000',
        title: 'Income Tax on $40K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_50000',
        title: 'Income Tax on $50K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_60000',
        title: 'Income Tax on $60K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_75000',
        title: 'Income Tax on $75K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_100000',
        title: 'Income Tax on $100K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_150000',
        title: 'Income Tax on $150K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_200000',
        title: 'Income Tax on $200K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_250000',
        title: 'Income Tax on $250K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_300000',
        title: 'Income Tax on $300K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_500000',
        title: 'Income Tax on $500K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_750000',
        title: 'Income Tax on $750K',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_1000000',
        title: 'Income Tax on $1M',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_2000000',
        title: 'Income Tax on $2M',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'income_tax_on_5000000',
        title: 'Income Tax on $5M',
        categories: ['finance', 'tax'],
        questions: [
            {template1: 'I\'d be willing to pay ', x: 0, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to pay ', x: 1, template2: ' dollars in tax if you paid me ', y: 0, template3: ' per year'},
        ],
    },
    {
        id: 'offset_minutes',
        title: 'Time Zone',
        categories: ['other'],
        questions: [
            {template1: 'I\'d be willing to live at time zone offset ', x: 0, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to live at time zone offset ', x: 1, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to live at time zone offset ', x: 2, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to live at time zone offset ', x: 3, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to live at time zone offset ', x: 4, template2: ' if you paid me ', y: 0, template3: ' per year'},
            {template1: 'I\'d be willing to live at time zone offset ', x: 5, template2: ' if you paid me ', y: 0, template3: ' per year'},
        ],
    },
];

