import React, { useState } from 'react';
import Map from './Map';
import MapContext from './Map/MapContext';
import { Layers, VectorTileLayer, ImageLayer } from './Map/Layers';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { fromLonLat, get } from 'ol/proj';
import { Controls, LayerSwitcherControl } from "./Map/Controls";
import GeoJSON from 'ol/format/GeoJSON';
import RasterSource from 'ol/source/Raster';
import VectorTileSource from 'ol/source/VectorTile';
import {fromLonLat} from 'ol/proj';
import {Text, Style, Fill, Stroke} from 'ol/style';
import MVT from 'ol/format/MVT';
import DynamicTileLayer from './DynamicTileLayer';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';

const emptyStyle = new Style();
const cityStyle = new Style({
  text: new Text({
      font: 'bold 11px "Open Sans", "Arial Unicode MS", "sans-serif"',
      //placement: 'line',
      fill: new Fill({
          color: 'black'
      }),
      stroke: new Stroke({
          color: 'white',
          width: 2
      }),

  })
});

const water = (pixels, data) => {
    const pixel = pixels[0];
    if (pixel[0] == 1) {
        pixel[0] = 165;
        pixel[1] = 191;
        pixel[2] = 221;
        pixel[3] = 255;
    } else {
        pixel[3] = 0;
    }
    return pixel;
}

const costfn = (pixels, data) => {
    const pixel = pixels[0];
    pixel[0] = pixel[1];
    //pixel[1] = 0;
    pixel[1] = 255 - pixel[1];
    pixel[2] = 0;
    //pixel[3] = 255 - pixel[1];
    return pixel;
}

const cityRenderOrder = (feature1, feature2) => {
    return feature2.get('population') - feature1.get('population');
}

const cityStyleFn = (map) => {
    return (feature, resolution) => {
        var zoom = map.getView().getZoomForResolution(resolution) - 1;
        var minPop = 217662370*zoom**-4.0910855;
        var pop = Number(feature.get('population'));
        if (pop > minPop) {
            cityStyle.getText().setText(feature.get('name'));
            cityStyle.setZIndex(pop);
            return cityStyle;
        } else {
            return emptyStyle;
        }
    };
};


const MainMap = (params) => {
    const [center, setCenter] = useState([-96.0,38.0]);
    const [zoom, setZoom] = useState(5.7);
    const [showCostLayer, setShowCostLayer] = useState(true);
    
    return (
        <Map center={fromLonLat(center)} zoom={zoom} projection='EPSG:3857'>
            <Layers>
                {showCostLayer && (<DynamicTileLayer costFunction={params.costFunction} zIndex={0} operation={costfn} />) }

                <ImageLayer      title="Water"
                                 source={new RasterSource({sources: [new TileLayer({source: new XYZ({url: "https://tile-{a-c}.places.jackohare.com/water_tiles/{z}/{x}/{y}.png", projection: 'EPSG:3857', minZoom: 0, maxZoom: 12, crossOrigin: 'anonymous'})})],
                                                           operation: water})}
                                 zIndex={1}
                />
                    
                <VectorTileLayer title="Roads"
                                 source={new VectorTileSource({format: new MVT(), url: `https://tile-{a-c}.places.jackohare.com/road_vector_tiles/{z}/{x}/{y}.pbf`, minZoom: 0, maxZoom: 9, crossOrigin: 'anonymous', projection: 'EPSG:3857'})}
                                 style={new Style({ stroke: new Stroke({ color: '#ff9e17', width: 0.4 }) })}
                                 minZoom={7}
                                 zIndex={2} />

                <VectorTileLayer title="States"
                                 source={new VectorTileSource({format: new MVT(), url: `https://tile-{a-c}.places.jackohare.com/state_vector_tiles/{z}/{x}/{y}.pbf`, minZoom: 0, maxZoom: 9, crossOrigin: 'anonymous', projection: 'EPSG:3857'})}
                                 style={new Style({ stroke: new Stroke({ color: 'black', width: 0.2 }) })}
                                 zIndex={3} />

                <VectorTileLayer title="Cities"
                                 source={new VectorTileSource({format: new MVT(), url: `https://tile-{a-c}.places.jackohare.com/city_vector_tiles/{z}/{x}/{y}.pbf`, minZoom: 0, maxZoom: 7, crossOrigin: 'anonymous', projection: 'EPSG:3857'})}
                                 style={cityStyleFn}
                                 renderOrder={cityRenderOrder}
                                 zIndex={4} />
                    
            
            </Layers>
            <Controls>
                <LayerSwitcherControl />
            </Controls>
        </Map>
    );
}

export default MainMap;


/*
import TileDebug from 'ol/source/TileDebug';

import sync from 'ol-hashed';
sync(map);
*/
