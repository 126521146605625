import { useContext, useEffect } from "react";
import MapContext from "../MapContext";
import ImageLayer from 'ol/layer/Image';

const ImageLayerExport = (props) => {
  const { map } = useContext(MapContext); 
  useEffect(() => {
      if (!map) return;
      let tileLayer = new ImageLayer(props);
      map.addLayer(tileLayer);
      //tileLayer.setZIndex(zIndex);
      return () => {
          if (map) {
              map.removeLayer(tileLayer);
          }
      };
  }, [map]);
  return null;
};
export default ImageLayerExport;
