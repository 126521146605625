import React, { useState } from 'react';
import LayerChooser from './LayerChooser';
import MainMap from './MainMap';
import './Site.css'

const Site = () => {
    const [costFunction, setCostFunction] = useState("0");

    return (
        <div id="site" class="d-flex flex-column">
            <nav class="navbar navbar-light bg-light">
                <a class="navbar-brand" href="#">
                    <img src="places.png" width="30" height="30" class="d-inline-block align-top" alt="" />
                    Places
                </a>
            </nav>
            <div class="d-flex flex-row flex-grow-1">
                <LayerChooser onCostFunctionChange={costFn => { setCostFunction(costFn); }} />
                <div class="flex-column flex-grow-1">
                    <MainMap costFunction={costFunction} />
                </div>
            </div>
            <footer class="footer">
                <div class="container">
                    <span class="text-muted">© Places</span>
                </div>
            </footer>
        </div>
    );
}

export default Site;
